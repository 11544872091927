import React from 'react';
import Layout from "../../layout/Layout";
import PageHeaderBlock from "../../blocks/PageHeaderBlock";
import SEO from "../../components/seo";
import OnlinebankCardsBlock from "../../blocks/OnlinebankCardsBlock";

import Block from "../../blocks/Block";
import BlockSection from "../../blocks/BlockSection";
import Title from "../../components/typography/Title";
import StepsList from "../../components/StepsListWithHappy";
import SignUpForm from "../../forms/instances/SignUpForm";

import ImageGallery from "../../blocks/ImageGallery";

import AppStoreApplication from "../../blocks/AppStoreApplication";

import enMessages from '../../i18n/online-banking/en.js'
import ruMessages from '../../i18n/online-banking/ru.js'

const messages = {
    en: enMessages,
    ru: ruMessages
}

const OnlineBankPage = ({ pathContext: { locale } }) => {
    return (
        <Layout locale={locale}>
            <SEO
                title={messages[locale].seo.title}
                description={messages[locale].seo.description}
            />
            <PageHeaderBlock
                locale={locale}
                title={messages[locale].pageHeaderBlock.title}
                text={messages[locale].pageHeaderBlock.text}
            />
            <OnlinebankCardsBlock
                cardsTitle={messages[locale].OnlinebankCardsBlock.cardsTitle}
                cards={messages[locale].OnlinebankCardsBlock.cards}
                cardsTitleColor='first'
                cardsTitlePosition='center'
            />

            <ImageGallery
                gallery={messages[locale].gallery}
            />

            <AppStoreApplication
                data={messages[locale].appstore}
            />

            <Block classNameInner="margin-none_bottom" config={{ baseBorder: true }}>
                <div id='SelfEmployed-StepsList' className="container">
                    <BlockSection className="margin-none">
                        <Title
                            level="h2"
                            color="second"
                            className="Block-Title text-center SelfEmployed_title"
                            headerStyleNumb={'10'}
                            innerHTML={messages[locale].submitApplicationBlock.cards.title}
                        />
                        <StepsList color='second' elements={messages[locale].submitApplicationBlock.cards.list} viewType="ol" />
                    </BlockSection>
                </div>

                <BlockSection className="Block_theme_form Block_theme_OnlineBanking">
                    <div className="Block-Form">
                        <SignUpForm
                            textSubmit={messages[locale].submitApplicationBlock.textSubmitBottom || messages[locale].submitApplicationBlock.textSubmit}
                        />
                    </div>
                </BlockSection>
            </Block>
        </Layout>
    )
};

export default OnlineBankPage;
